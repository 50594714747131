




import Vue from 'vue';

export default Vue.extend({
  layout: 'layout-exterior',
  mounted() {
    this.$router.replace('/ktgl/learnit');
  },
});
