// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2022 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import Int64LookupResourceViewModel from '../../../../viewModel/resource/Int64LookupResourceViewModel';
import LearnitFavoriteResourceViewModel from '../../../../viewModel/resource/LearnitFavoriteResourceViewModel';
import LearningProgressResourceViewModel from '../../../../viewModel/resource/LearningProgressResourceViewModel';
import LearningPathUnitListResourceDTO from '../../dto/resource/LearningPathUnitListResourceDTO';

export default abstract class LearningPathUnitListResourceModel extends BaseModel<LearningPathUnitListResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get creatorId() { return this.dto.creatorId; }
  set creatorId(value) { this.dto.creatorId = value; }
  /**
  */
  get creatorName() { return this.dto.creatorName; }
  set creatorName(value) { this.dto.creatorName = value; }
  /**
  * @type {date-time}
  */
  get created() { return this.dto.created; }
  set created(value) { this.dto.created = value; }
  /**
  * @type {int64}
  */
  get modifierId() { return this.dto.modifierId; }
  set modifierId(value) { this.dto.modifierId = value; }
  /**
  */
  get modifierName() { return this.dto.modifierName; }
  set modifierName(value) { this.dto.modifierName = value; }
  /**
  * @type {date-time}
  */
  get modified() { return this.dto.modified; }
  set modified(value) { this.dto.modified = value; }
  /**
  */
  get shortCode() { return this.dto.shortCode; }
  set shortCode(value) { this.dto.shortCode = value; }
  /**
  */
  get title() { return this.dto.title; }
  set title(value) { this.dto.title = value; }
  /**
  */
  get subTitle() { return this.dto.subTitle; }
  set subTitle(value) { this.dto.subTitle = value; }
  /**
  */
  get comment() { return this.dto.comment; }
  set comment(value) { this.dto.comment = value; }
  /**
  * @type {int64}
  */
  get pictureDocumentId() { return this.dto.pictureDocumentId; }
  set pictureDocumentId(value) { this.dto.pictureDocumentId = value; }
  /**
  */
  get level() { return this.getObjectModel(() => this.dto.level, Int64LookupResourceViewModel)!; }
  set level(value) { this.setObjectModel(() => this.dto.level, value); }
  /**
  */
  get status() { return this.getObjectModel(() => this.dto.status, Int64LookupResourceViewModel)!; }
  set status(value) { this.setObjectModel(() => this.dto.status, value); }
  /**
  */
  get isPublic() { return this.dto.isPublic; }
  set isPublic(value) { this.dto.isPublic = value; }
  /**
  */
  get contentType() { return this.getObjectModel(() => this.dto.contentType, Int64LookupResourceViewModel)!; }
  set contentType(value) { this.setObjectModel(() => this.dto.contentType, value); }
  /**
  */
  get contentTypePath() { return this.dto.contentTypePath; }
  set contentTypePath(value) { this.dto.contentTypePath = value; }
  /**
  * @type {int32}
  */
  get sort() { return this.dto.sort; }
  set sort(value) { this.dto.sort = value; }
  /**
  * @type {int32}
  */
  get countOfLearningPaths() { return this.dto.countOfLearningPaths; }
  set countOfLearningPaths(value) { this.dto.countOfLearningPaths = value; }
  /**
  */
  get favorite() { return this.getObjectModel(() => this.dto.favorite, LearnitFavoriteResourceViewModel)!; }
  set favorite(value) { this.setObjectModel(() => this.dto.favorite, value); }
  /**
  */
  get learningProgress() { return this.getObjectModel(() => this.dto.learningProgress, LearningProgressResourceViewModel)!; }
  set learningProgress(value) { this.setObjectModel(() => this.dto.learningProgress, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    value = Number.parseFloat(queries.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(queries.creatorId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.creatorId = value;
    this.dto.creatorName = queries.creatorName;
    this.dto.created = queries.created;
    value = Number.parseFloat(queries.modifierId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.modifierId = value;
    this.dto.modifierName = queries.modifierName;
    this.dto.modified = queries.modified;
    this.dto.shortCode = queries.shortCode;
    this.dto.title = queries.title;
    this.dto.subTitle = queries.subTitle;
    this.dto.comment = queries.comment;
    value = Number.parseFloat(queries.pictureDocumentId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.pictureDocumentId = value;
    value = queries.isPublic === 'true' ? true : queries.isPublic;
    value = queries.isPublic === 'false' ? false : undefined;
    this.dto.isPublic = value;
    this.dto.contentTypePath = queries.contentTypePath;
    value = Number.parseFloat(queries.sort);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.sort = value;
    value = Number.parseFloat(queries.countOfLearningPaths);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.countOfLearningPaths = value;
  }
}
