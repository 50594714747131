// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2022 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetFoldersRequestViewModel from '../../viewModel/request/Documents/GetFoldersRequestViewModel';
import UploadZipDocumentRequestViewModel from '../../viewModel/request/Documents/UploadZipDocumentRequestViewModel';
import GetDocumentsRequestViewModel from '../../viewModel/request/Documents/GetDocumentsRequestViewModel';
import UploadDocumentRequestViewModel from '../../viewModel/request/Documents/UploadDocumentRequestViewModel';
import StringListResultViewModel from '../../viewModel/resource/StringListResultViewModel';
import DocumentResourcePagingResultViewModel from '../../viewModel/resource/DocumentResourcePagingResultViewModel';
import DocumentResourceViewModel from '../../viewModel/resource/DocumentResourceViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getFolders(request: GetFoldersRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-folders'] ?? 'Learnit/Documents/GetFolders';
    const response = await service.get<any>(endpointPath, {
      query: {
        entityType: requestDTO.entityType,
        entityId: requestDTO.entityId,
      },
      ...config,
    });
    const dto = response.data.result;
    return new StringListResultViewModel().fromDTO(dto);
  },
  /**  */
  async uploadZipDocument(request: UploadZipDocumentRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    const formData = new FormData();
    formData.append('uploadFile', requestDTO.uploadFile as Blob);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-upload-zip-document'] ?? 'Learnit/Documents/Upload';
    const response = await service.post<any>(endpointPath, {
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      query: {
        uploadFilename: requestDTO.uploadFilename,
        entityType: requestDTO.entityType,
        entityId: requestDTO.entityId,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async getDocuments(request: GetDocumentsRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-documents'] ?? 'core/Documents';
    const response = await service.get<any>(endpointPath, {
      query: {
        fileExtension: requestDTO.fileExtension,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new DocumentResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async getDocumentById(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-document-by-id'] ?? 'core/Documents/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new DocumentResourceViewModel().fromDTO(dto);
  },
  /**  */
  async uploadDocument(request: UploadDocumentRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    const formData = new FormData();
    formData.append('uploadFile', requestDTO.uploadFile as Blob);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-upload-document'] ?? 'core/Documents/Upload';
    const response = await service.post<any>(endpointPath, {
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async downloadDocument(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-download-document'] ?? 'core/Documents/{id}/Download';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      responseType: 'blob',
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return new Blob([result], { type: 'application/octet-stream' });
  },
});
