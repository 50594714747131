import coreRoutes from '@glittr/frontend-core/src/routes';
import Home from './views/pages/index.vue';

export default {
  mode: 'history',
  base: process.env!.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/_me/profile',
      name: 'learnit-profile',
      component: () => import('./views/pages/_me/profile.vue'),
    },
    ...coreRoutes,
    {
      path: '/:tenant/:workspace',
      name: 'dashboard',
      component: () => import('./views/pages/app/dashboard.vue'),
    },
    {
      path: '/:tenant/:workspace/learningUnits',
      name: 'learning-units',
      component: () => import('./views/pages/app/learningUnits/list.vue'),
    },
    {
      path: '/:tenant/:workspace/learningUnits/create',
      name: 'learning-units-detail-create',
      component: () => import('./views/pages/app/learningUnits/detailEdit.vue'),
    },
    {
      path: '/:tenant/:workspace/learningUnits/favorites',
      name: 'learning-units-favorites',
      component: () => import('./views/pages/app/learningUnits/favorites.vue'),
    },
    {
      path: '/:tenant/:workspace/learningUnits/started',
      name: 'learning-paths-started',
      component: () => import('./views/pages/app/learningUnits/started.vue'),
    },
    {
      path: '/:tenant/:workspace/learningUnits/finished',
      name: 'learning-paths-finished',
      component: () => import('./views/pages/app/learningUnits/finished.vue'),
    },
    {
      path: '/:tenant/:workspace/learningUnits/:learningUnitId',
      name: 'learning-units-detail',
      component: () => import('./views/pages/app/learningUnits/detail.vue'),
    },
    {
      path: '/:tenant/:workspace/learningUnits/:learningUnitId/edit',
      name: 'learning-units-detail-edit',
      component: () => import('./views/pages/app/learningUnits/detailEdit.vue'),
    },
    {
      path: '/:tenant/:workspace/learningPaths',
      name: 'learning-paths',
      component: () => import('./views/pages/app/learningPaths/list.vue'),
    },
    {
      path: '/:tenant/:workspace/learningPaths/create',
      name: 'learning-paths-detail-create',
      component: () => import('./views/pages/app/learningPaths/detailEdit.vue'),
    },
    {
      path: '/:tenant/:workspace/learningPaths/:learningPathId/learningUnits/:learningUnitId',
      name: 'learning-paths-learning-units-detail',
      component: () => import('./views/pages/app/learningUnits/detail.vue'),
    },
    {
      path: '/:tenant/:workspace/learningPaths/favorites',
      name: 'learning-paths-favorites',
      component: () => import('./views/pages/app/learningPaths/favorites.vue'),
    },
    {
      path: '/:tenant/:workspace/learningPaths/started',
      name: 'learning-paths-started',
      component: () => import('./views/pages/app/learningPaths/started.vue'),
    },
    {
      path: '/:tenant/:workspace/learningPaths/finished',
      name: 'learning-paths-finished',
      component: () => import('./views/pages/app/learningPaths/finished.vue'),
    },
    {
      path: '/:tenant/:workspace/learningPaths/:learningPathId',
      name: 'learning-paths-detail',
      component: () => import('./views/pages/app/learningPaths/detail.vue'),
    },
    {
      path: '/:tenant/:workspace/learningPaths/:learningPathId/edit',
      name: 'learning-paths-detail-edit',
      component: () => import('./views/pages/app/learningPaths/detailEdit.vue'),
    },
    {
      path: '/:tenant/:workspace/users/accessCode',
      name: 'users-access-code',
      component: () => import('./views/pages/app/users/accessCode.vue'),
    },
    {
      path: '/:tenant/:workspace/users/accessCodes',
      name: 'accessCodes',
      component: () => import('./views/pages/app/users/accessCodeList.vue'),
    },
    {
      path: '/:tenant/:workspace/users/tags',
      name: 'tags',
      component: () => import('./views/pages/app/users/tags.vue'),
    },
  ],
};
