


















import Vue, { PropType } from 'vue';
import LearningProgressResourceViewModel from '../services/viewModel/resource/LearningProgressResourceViewModel';

export default Vue.extend({
  name: 'KtglLearningProgress',
  props: {
    value: { type: Object as PropType<LearningProgressResourceViewModel | undefined>, default: undefined },
  },
  data: () => ({
    progress: new LearningProgressResourceViewModel(),
  }),
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this._.isSet(this.value)) {
          this.progress.fromModel(this.value);
        } else {
          this.progress = new LearningProgressResourceViewModel();
        }
      },
    },
  },
});
