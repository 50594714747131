











import Vue from 'vue';

export default Vue.extend({
  name: 'KtglDocumentImage',
  props: {
    defaultPicturePath: { type: String, default: '/img/learnit-unit-default.png' },
    pictureId: { type: [String, Number], default: undefined },
    ratio: { type: [String, Number], default: undefined },
    cover: { type: Boolean, default: false },
    height: { type: [String, Number], default: undefined },
    width: { type: [String, Number], default: undefined },
  },
  data: () => ({
    isLoading: true,
    // Transparent pixel
    picturePath: 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
  }),
  computed: {
    classList() {
      return {
        'ktgl-document-image': true,
        loaded: !this.isLoading,
      };
    },
  },
  watch: {
    pictureId: {
      immediate: true,
      handler() {
        this.$debounce(() => {
          this.loadImage(this.pictureId);
        }, 600, this)();
      },
    },
  },
  methods: {
    async loadImage(documentId: string | number) {
      this.isLoading = true;
      if (documentId) {
        const blob = await this.$service.api.documents.downloadDocument(documentId as any);
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          this.picturePath = `${reader.result?.toString()}`;
        };
      } else {
        this.picturePath = this.defaultPicturePath;
      }
      this.isLoading = false;
    },
  },
});
