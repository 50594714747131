// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2022 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetLearningPathPagedListRequestViewModel from '../../viewModel/request/LearnitPaths/GetLearningPathPagedListRequestViewModel';
import GetLearningPathUnitByLearningPathPagedListRequestViewModel from '../../viewModel/request/LearnitPaths/GetLearningPathUnitByLearningPathPagedListRequestViewModel';
import GetFavoriteLearningPathsRequestViewModel from '../../viewModel/request/LearnitPaths/GetFavoriteLearningPathsRequestViewModel';
import GetStartedLearningPathsRequestViewModel from '../../viewModel/request/LearnitPaths/GetStartedLearningPathsRequestViewModel';
import GetFinishedLearningPathsRequestViewModel from '../../viewModel/request/LearnitPaths/GetFinishedLearningPathsRequestViewModel';
import DownloadLearningPathCertificateRequestViewModel from '../../viewModel/request/LearnitPaths/DownloadLearningPathCertificateRequestViewModel';
import LearningPathResourceViewModel from '../../viewModel/resource/LearningPathResourceViewModel';
import LearningPathListResourcePagingResultViewModel from '../../viewModel/resource/LearningPathListResourcePagingResultViewModel';
import LearningPathListResourceListResultViewModel from '../../viewModel/resource/LearningPathListResourceListResultViewModel';
import LearningPathUnitListResourcePagingResultViewModel from '../../viewModel/resource/LearningPathUnitListResourcePagingResultViewModel';
import LearnitTagResourcePagingResultViewModel from '../../viewModel/resource/LearnitTagResourcePagingResultViewModel';
import LearningPath_TagResourceViewModel from '../../viewModel/resource/LearningPath_TagResourceViewModel';
import SetSortRequestViewModel from '../../viewModel/resource/SetSortRequestViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getLearningPathById(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-learning-path-by-id'] ?? 'Learnit/LearningPaths/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new LearningPathResourceViewModel().fromDTO(dto);
  },
  /**  */
  async updateLearningPath(id: number, model: LearningPathResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-learning-path'] ?? 'Learnit/LearningPaths/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.put<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async deleteLearningPath(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-learning-path'] ?? 'Learnit/LearningPaths/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async getLearningPathPagedList(request: GetLearningPathPagedListRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-learning-path-paged-list'] ?? 'Learnit/LearningPaths';
    const response = await service.get<any>(endpointPath, {
      query: {
        userId: requestDTO.userId,
        statusIds: requestDTO.statusIds,
        levelIds: requestDTO.levelIds,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new LearningPathListResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async createLearningPath(model: LearningPathResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-learning-path'] ?? 'Learnit/LearningPaths';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async getSimilarLearningPathList(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-similar-learning-path-list'] ?? 'Learnit/LearningPaths/{id}/SimilarLearningPathList';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new LearningPathListResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async getLearningPathUnitByLearningPathPagedList(id: number, request: GetLearningPathUnitByLearningPathPagedListRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-learning-path-unit-by-learning-path-paged-list'] ?? 'Learnit/LearningPaths/{id}/LearningPathUnits';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        userId: requestDTO.userId,
        validFrom: requestDTO.validFrom,
        validUntil: requestDTO.validUntil,
        statusIds: requestDTO.statusIds,
        levelIds: requestDTO.levelIds,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new LearningPathUnitListResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async getFavoriteLearningPaths(request: GetFavoriteLearningPathsRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-favorite-learning-paths'] ?? 'Learnit/LearningPaths/Favorites';
    const response = await service.get<any>(endpointPath, {
      query: {
        userId: requestDTO.userId,
        statusIds: requestDTO.statusIds,
        levelIds: requestDTO.levelIds,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new LearningPathListResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async getStartedLearningPaths(request: GetStartedLearningPathsRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-started-learning-paths'] ?? 'Learnit/LearningPaths/Mine/Started';
    const response = await service.get<any>(endpointPath, {
      query: {
        userId: requestDTO.userId,
        statusIds: requestDTO.statusIds,
        levelIds: requestDTO.levelIds,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new LearningPathListResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async getFinishedLearningPaths(request: GetFinishedLearningPathsRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-finished-learning-paths'] ?? 'Learnit/LearningPaths/Mine/Finished';
    const response = await service.get<any>(endpointPath, {
      query: {
        userId: requestDTO.userId,
        statusIds: requestDTO.statusIds,
        levelIds: requestDTO.levelIds,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new LearningPathListResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async getTagsByLearningPathId(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-tags-by-learning-path-id'] ?? 'Learnit/LearningPaths/{id}/Tags';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new LearnitTagResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async addTagToLearningPath(model: LearningPath_TagResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-add-tag-to-learning-path'] ?? 'Learnit/LearningPaths/AddTag';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async removeTagFromLearningPath(id: number, tagId: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-remove-tag-from-learning-path'] ?? 'Learnit/LearningPaths/{id}/RemoveTag/{tagId}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    endpointPath = endpointPath.replace(/\{tagId\}/g, `${tagId}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async addLearningUnit(id: number, learningUnitId: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-add-learning-unit'] ?? 'Learnit/LearningPaths/{id}/LearningPathUnits/{learningUnitId}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    endpointPath = endpointPath.replace(/\{learningUnitId\}/g, `${learningUnitId}`);
    const response = await service.put<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async removeLearningUnit(id: number, learningUnitId: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-remove-learning-unit'] ?? 'Learnit/LearningPaths/{id}/LearningPathUnits/{learningUnitId}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    endpointPath = endpointPath.replace(/\{learningUnitId\}/g, `${learningUnitId}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async updateLearningUnitSort(id: number, learningUnitId: number, model: SetSortRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-learning-unit-sort'] ?? 'Learnit/LearningPaths/{id}/LearningPathUnits/{learningUnitId}/Sort';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    endpointPath = endpointPath.replace(/\{learningUnitId\}/g, `${learningUnitId}`);
    const response = await service.put<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async downloadLearningPathCertificate(id: number, request: DownloadLearningPathCertificateRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-download-learning-path-certificate'] ?? 'Learnit/LearningPaths/{id}/Download';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      responseType: 'blob',
      query: {
        userId: requestDTO.userId,
        validFrom: requestDTO.validFrom,
        validUntil: requestDTO.validUntil,
        statusIds: requestDTO.statusIds,
        levelIds: requestDTO.levelIds,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return new Blob([result], { type: 'application/octet-stream' });
  },
});
