





















import Vue from 'vue';
import ktglDocumentImage from './ktgl-document-image.vue';

export default Vue.extend({
  name: 'KtglLearningContentLead',
  components: { ktglDocumentImage },
  props: {
    pictureId: { type: [String, Number], default: undefined },
    loading: { type: Boolean, default: false },
  },
  data: () => ({
    hoistedImageEl: undefined as undefined | Element,
  }),
  computed: {
    classList(): Record<string, boolean> {
      return {
        'ktgl-learning-entry': true,
        loaded: !this.loading,
      };
    },
  },
  watch: {
    loading() {
      if (!this.loading) {
        this.$nextTick(() => {
          this.hoistLeadImage();
        });
      }
    },
  },
  beforeDestroy() {
    this.hoistedImageEl?.remove();
  },
  methods: {
    hoistLeadImage() {
      if (this.hoistedImageEl) {
        return;
      }
      const leadImage = this.$refs.leadImage as Vue;
      const imageEl = this._.getElement(leadImage);
      const queue = [] as any[];
      queue.push(imageEl.parentNode);
      let container = undefined as undefined | HTMLElement;
      while (queue.length > 0) {
        const parent = queue.pop();
        if ([...(parent.classList ?? [])].includes('p-container')) {
          container = parent.parentNode;
          break;
        }
        if (parent.parentNode) {
          queue.push(parent.parentNode);
        }
      }
      this.hoistedImageEl = imageEl;
      container?.prepend(imageEl);
    },
  },
});
