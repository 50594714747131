// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2022 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetLearningPathUnitPagedListRequestViewModel from '../../viewModel/request/LearnitUnits/GetLearningPathUnitPagedListRequestViewModel';
import GetFavoriteLearningPathUnitsRequestViewModel from '../../viewModel/request/LearnitUnits/GetFavoriteLearningPathUnitsRequestViewModel';
import GetStartedLearningPathUnitsRequestViewModel from '../../viewModel/request/LearnitUnits/GetStartedLearningPathUnitsRequestViewModel';
import GetFinishedLearningPathUnitsRequestViewModel from '../../viewModel/request/LearnitUnits/GetFinishedLearningPathUnitsRequestViewModel';
import LearningPathUnitResourceViewModel from '../../viewModel/resource/LearningPathUnitResourceViewModel';
import LearningPathUnitListResourcePagingResultViewModel from '../../viewModel/resource/LearningPathUnitListResourcePagingResultViewModel';
import LearningPathUnitListResourceListResultViewModel from '../../viewModel/resource/LearningPathUnitListResourceListResultViewModel';
import LearnitTagResourcePagingResultViewModel from '../../viewModel/resource/LearnitTagResourcePagingResultViewModel';
import LearningPathUnit_TagResourceViewModel from '../../viewModel/resource/LearningPathUnit_TagResourceViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getLearningPathUnitById(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-learning-path-unit-by-id'] ?? 'Learnit/LearningPathUnits/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new LearningPathUnitResourceViewModel().fromDTO(dto);
  },
  /**  */
  async updateLearningPathUnit(id: number, model: LearningPathUnitResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-learning-path-unit'] ?? 'Learnit/LearningPathUnits/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.put<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async deleteLearningPathUnit(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-learning-path-unit'] ?? 'Learnit/LearningPathUnits/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async getLearningPathUnitPagedList(request: GetLearningPathUnitPagedListRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-learning-path-unit-paged-list'] ?? 'Learnit/LearningPathUnits';
    const response = await service.get<any>(endpointPath, {
      query: {
        userId: requestDTO.userId,
        validFrom: requestDTO.validFrom,
        validUntil: requestDTO.validUntil,
        statusIds: requestDTO.statusIds,
        levelIds: requestDTO.levelIds,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new LearningPathUnitListResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async createLearningPathUnit(model: LearningPathUnitResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-learning-path-unit'] ?? 'Learnit/LearningPathUnits';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async getSimilarLearningPathUnitList(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-similar-learning-path-unit-list'] ?? 'Learnit/LearningPathUnits/{id}/SimilarLearningPathUnitList';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new LearningPathUnitListResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async getFavoriteLearningPathUnits(request: GetFavoriteLearningPathUnitsRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-favorite-learning-path-units'] ?? 'Learnit/LearningPathUnits/Favorites';
    const response = await service.get<any>(endpointPath, {
      query: {
        userId: requestDTO.userId,
        validFrom: requestDTO.validFrom,
        validUntil: requestDTO.validUntil,
        statusIds: requestDTO.statusIds,
        levelIds: requestDTO.levelIds,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new LearningPathUnitListResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async getStartedLearningPathUnits(request: GetStartedLearningPathUnitsRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-started-learning-path-units'] ?? 'Learnit/LearningPathUnits/Mine/Started';
    const response = await service.get<any>(endpointPath, {
      query: {
        userId: requestDTO.userId,
        validFrom: requestDTO.validFrom,
        validUntil: requestDTO.validUntil,
        statusIds: requestDTO.statusIds,
        levelIds: requestDTO.levelIds,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new LearningPathUnitListResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async getFinishedLearningPathUnits(request: GetFinishedLearningPathUnitsRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-finished-learning-path-units'] ?? 'Learnit/LearningPathUnits/Mine/Finished';
    const response = await service.get<any>(endpointPath, {
      query: {
        userId: requestDTO.userId,
        validFrom: requestDTO.validFrom,
        validUntil: requestDTO.validUntil,
        statusIds: requestDTO.statusIds,
        levelIds: requestDTO.levelIds,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new LearningPathUnitListResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async getTagsByLearningPathUnitId(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-tags-by-learning-path-unit-id'] ?? 'Learnit/LearningPathUnits/{id}/Tags';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new LearnitTagResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async addTagToLearningPathUnit(model: LearningPathUnit_TagResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-add-tag-to-learning-path-unit'] ?? 'Learnit/LearningPathUnits/AddTag';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async removeTagFromLearningPathUnit(id: number, tagId: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-remove-tag-from-learning-path-unit'] ?? 'Learnit/LearningPathUnits/{id}/RemoveTag/{tagId}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    endpointPath = endpointPath.replace(/\{tagId\}/g, `${tagId}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async downloadLearningPathUnitCertificate(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-download-learning-path-unit-certificate'] ?? 'Learnit/LearningPathUnits/{id}/Download';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      responseType: 'blob',
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return new Blob([result], { type: 'application/octet-stream' });
  },
});
