// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2022 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import GetLearningPathUnitPagedListRequestDTO from '../../dto/request/GetLearningPathUnitPagedListRequestDTO';

export default abstract class GetLearningPathUnitPagedListRequestModel extends BaseModel<GetLearningPathUnitPagedListRequestDTO> {
  // #region    ──BodyRequest──────────────────────────╮
  // #endregion ───────────────────────────────────────╯
  // #region    ──HeaderRequest──────────────────────────╮
  // #endregion ─────────────────────────────────────────╯
  // #region    ──QueryRequest──────────────────────────╮
  /**
  * @type {int64}
  */
  get userId() { return this.dto.userId; }
  set userId(value) { this.dto.userId = value; }
  /**
  * @type {date-time}
  */
  get validFrom() { return this.dto.validFrom; }
  set validFrom(value) { this.dto.validFrom = value; }
  /**
  * @type {date-time}
  */
  get validUntil() { return this.dto.validUntil; }
  set validUntil(value) { this.dto.validUntil = value; }
  /**
  */
  get statusIds() { return this.dto.statusIds; }
  set statusIds(value) { this.dto.statusIds = value; }
  /**
  */
  get levelIds() { return this.dto.levelIds; }
  set levelIds(value) { this.dto.levelIds = value; }
  /**
  */
  get searchCriteria() { return this.dto.searchCriteria; }
  set searchCriteria(value) { this.dto.searchCriteria = value; }
  /**
  * @type {int32}
  */
  get 'paging.CurrentPage'() { return this.dto['paging.CurrentPage']; }
  set 'paging.CurrentPage'(value) { this.dto['paging.CurrentPage'] = value; }
  /**
  * @type {int32}
  */
  get 'paging.PageSize'() { return this.dto['paging.PageSize']; }
  set 'paging.PageSize'(value) { this.dto['paging.PageSize'] = value; }
  /**
  */
  get 'paging.IncludeCount'() { return this.dto['paging.IncludeCount']; }
  set 'paging.IncludeCount'(value) { this.dto['paging.IncludeCount'] = value; }
  /**
  */
  get 'paging.NoPaging'() { return this.dto['paging.NoPaging']; }
  set 'paging.NoPaging'(value) { this.dto['paging.NoPaging'] = value; }
  // #endregion ────────────────────────────────────────╯

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    value = Number.parseFloat(queries.userId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.userId = value;
    this.dto.validFrom = queries.validFrom;
    this.dto.validUntil = queries.validUntil;
    this.dto.searchCriteria = queries.searchCriteria;
    value = Number.parseFloat(queries['paging.CurrentPage']);
    value = Number.isNaN(value) ? undefined : value;
    this.dto['paging.CurrentPage'] = value;
    value = Number.parseFloat(queries['paging.PageSize']);
    value = Number.isNaN(value) ? undefined : value;
    this.dto['paging.PageSize'] = value;
    value = queries['paging.IncludeCount'] === 'true' ? true : queries['paging.IncludeCount'];
    value = queries['paging.IncludeCount'] === 'false' ? false : undefined;
    this.dto['paging.IncludeCount'] = value;
    value = queries['paging.NoPaging'] === 'true' ? true : queries['paging.NoPaging'];
    value = queries['paging.NoPaging'] === 'false' ? false : undefined;
    this.dto['paging.NoPaging'] = value;
  }
}
