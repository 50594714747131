import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import LearnitTagsApi from '../generated/api/LearnitTags';

export default (service: Servicelayer) => ({
  ...LearnitTagsApi(service),

  // Add custom calls here

});
