













































import Vue from 'vue';
import LearningProgressResourceViewModel from '../services/viewModel/resource/LearningProgressResourceViewModel';
import LearnitFavoriteResourceViewModel from '../services/viewModel/resource/LearnitFavoriteResourceViewModel';

export default Vue.extend({
  name: 'KtglLearningContentCard',
  props: {
    title: { type: String, default: undefined },
    subtitle: { type: String, default: undefined },
    description: { type: String, default: undefined },
    isPublic: { type: Boolean, default: false },
    horizontal: { type: Boolean, default: false },
    pictureId: { type: [String, Number], default: undefined },
    hasImage: { type: Boolean, default: false },
    favorite: { type: LearnitFavoriteResourceViewModel, default: undefined },
    progress: { type: LearningProgressResourceViewModel, default: undefined },
  },
  data: () => ({
    cardSummaryMaxLength: 200,
  }),
  computed: {
    descriptionCharCount(): number {
      if (this.subtitle) {
        const subTitleLength = this.subtitle.length;
        if (subTitleLength >= this.cardSummaryMaxLength) {
          return 0;
        }
        return this.cardSummaryMaxLength - subTitleLength;
      }
      return this.cardSummaryMaxLength;
    },
    computedHorizontal(): boolean {
      return this.horizontal && this.$media.isDesktop;
    },
    hasAccess(): boolean {
      return this.$service.api.users.getHasAccessToContent(this.isPublic);
    },
  },
});
