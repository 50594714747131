import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import FavoritesApi from '../generated/api/Favorites';

export default (service: Servicelayer) => ({
  ...FavoritesApi(service),

  // Add custom calls here

});
