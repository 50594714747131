





















import Vue from 'vue';

export default Vue.extend({
  name: 'KtglLearningLock',
  props: {
    isPublic: { type: Boolean, default: false },
  },
  data: () => ({
    cardSummaryMaxLength: 200,
  }),
  computed: {
    hasAccess(): boolean {
      return this.$service.api.users.getHasAccessToContent(this.isPublic);
    },
  },
});
