// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2022 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import LearnitFavoriteResourceViewModel from '../../../../viewModel/resource/LearnitFavoriteResourceViewModel';
import LearningProgressResourceViewModel from '../../../../viewModel/resource/LearningProgressResourceViewModel';
import LearningPathUnitResourceDTO from '../../dto/resource/LearningPathUnitResourceDTO';

export default abstract class LearningPathUnitResourceModel extends BaseModel<LearningPathUnitResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get creatorId() { return this.dto.creatorId; }
  set creatorId(value) { this.dto.creatorId = value; }
  /**
  */
  get creatorName() { return this.dto.creatorName; }
  set creatorName(value) { this.dto.creatorName = value; }
  /**
  * @type {date-time}
  */
  get created() { return this.dto.created; }
  set created(value) { this.dto.created = value; }
  /**
  * @type {int64}
  */
  get modifierId() { return this.dto.modifierId; }
  set modifierId(value) { this.dto.modifierId = value; }
  /**
  */
  get modifierName() { return this.dto.modifierName; }
  set modifierName(value) { this.dto.modifierName = value; }
  /**
  * @type {date-time}
  */
  get modified() { return this.dto.modified; }
  set modified(value) { this.dto.modified = value; }
  /**
  */
  get shortCode() { return this.dto.shortCode; }
  set shortCode(value) { this.dto.shortCode = value; }
  /**
  */
  get title() { return this.dto.title; }
  set title(value) { this.dto.title = value; }
  /**
  */
  get subTitle() { return this.dto.subTitle; }
  set subTitle(value) { this.dto.subTitle = value; }
  /**
  */
  get comment() { return this.dto.comment; }
  set comment(value) { this.dto.comment = value; }
  /**
  * @type {int64}
  */
  get pictureDocumentId() { return this.dto.pictureDocumentId; }
  set pictureDocumentId(value) { this.dto.pictureDocumentId = value; }
  /**
  * @type {int64}
  */
  get topicId() { return this.dto.topicId; }
  set topicId(value) { this.dto.topicId = value; }
  /**
  * @type {int64}
  */
  get competenceAreaId() { return this.dto.competenceAreaId; }
  set competenceAreaId(value) { this.dto.competenceAreaId = value; }
  /**
  * @type {int64}
  */
  get levelId() { return this.dto.levelId; }
  set levelId(value) { this.dto.levelId = value; }
  /**
  * @type {int64}
  */
  get statusId() { return this.dto.statusId; }
  set statusId(value) { this.dto.statusId = value; }
  /**
  */
  get contentTypePath() { return this.dto.contentTypePath; }
  set contentTypePath(value) { this.dto.contentTypePath = value; }
  /**
  */
  get contentTypeUrl() { return this.dto.contentTypeUrl; }
  set contentTypeUrl(value) { this.dto.contentTypeUrl = value; }
  /**
  * @type {int64}
  */
  get contentTypeId() { return this.dto.contentTypeId; }
  set contentTypeId(value) { this.dto.contentTypeId = value; }
  /**
  */
  get isPublic() { return this.dto.isPublic; }
  set isPublic(value) { this.dto.isPublic = value; }
  /**
  * @type {date-time}
  */
  get validFrom() { return this.dto.validFrom; }
  set validFrom(value) { this.dto.validFrom = value; }
  /**
  * @type {date-time}
  */
  get validUntil() { return this.dto.validUntil; }
  set validUntil(value) { this.dto.validUntil = value; }
  /**
  */
  get favorite() { return this.getObjectModel(() => this.dto.favorite, LearnitFavoriteResourceViewModel)!; }
  set favorite(value) { this.setObjectModel(() => this.dto.favorite, value); }
  /**
  */
  get learningProgress() { return this.getObjectModel(() => this.dto.learningProgress, LearningProgressResourceViewModel)!; }
  set learningProgress(value) { this.setObjectModel(() => this.dto.learningProgress, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    value = Number.parseFloat(queries.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(queries.creatorId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.creatorId = value;
    this.dto.creatorName = queries.creatorName;
    this.dto.created = queries.created;
    value = Number.parseFloat(queries.modifierId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.modifierId = value;
    this.dto.modifierName = queries.modifierName;
    this.dto.modified = queries.modified;
    this.dto.shortCode = queries.shortCode;
    this.dto.title = queries.title;
    this.dto.subTitle = queries.subTitle;
    this.dto.comment = queries.comment;
    value = Number.parseFloat(queries.pictureDocumentId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.pictureDocumentId = value;
    value = Number.parseFloat(queries.topicId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.topicId = value;
    value = Number.parseFloat(queries.competenceAreaId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.competenceAreaId = value;
    value = Number.parseFloat(queries.levelId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.levelId = value;
    value = Number.parseFloat(queries.statusId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.statusId = value;
    this.dto.contentTypePath = queries.contentTypePath;
    this.dto.contentTypeUrl = queries.contentTypeUrl;
    value = Number.parseFloat(queries.contentTypeId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.contentTypeId = value;
    value = queries.isPublic === 'true' ? true : queries.isPublic;
    value = queries.isPublic === 'false' ? false : undefined;
    this.dto.isPublic = value;
    this.dto.validFrom = queries.validFrom;
    this.dto.validUntil = queries.validUntil;
  }
}
