// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2022 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import LearningProgressResourceDTO from '../../dto/resource/LearningProgressResourceDTO';

export default abstract class LearningProgressResourceModel extends BaseModel<LearningProgressResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get creatorId() { return this.dto.creatorId; }
  set creatorId(value) { this.dto.creatorId = value; }
  /**
  */
  get creatorName() { return this.dto.creatorName; }
  set creatorName(value) { this.dto.creatorName = value; }
  /**
  * @type {date-time}
  */
  get created() { return this.dto.created; }
  set created(value) { this.dto.created = value; }
  /**
  * @type {int64}
  */
  get modifierId() { return this.dto.modifierId; }
  set modifierId(value) { this.dto.modifierId = value; }
  /**
  */
  get modifierName() { return this.dto.modifierName; }
  set modifierName(value) { this.dto.modifierName = value; }
  /**
  * @type {date-time}
  */
  get modified() { return this.dto.modified; }
  set modified(value) { this.dto.modified = value; }
  /**
  * @type {int64}
  */
  get userId() { return this.dto.userId; }
  set userId(value) { this.dto.userId = value; }
  /**
  */
  get entityType() { return this.dto.entityType; }
  set entityType(value) { this.dto.entityType = value; }
  /**
  * @type {int64}
  */
  get entityId() { return this.dto.entityId; }
  set entityId(value) { this.dto.entityId = value; }
  /**
  * @type {int32}
  */
  get statusPercentage() { return this.dto.statusPercentage; }
  set statusPercentage(value) { this.dto.statusPercentage = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    value = Number.parseFloat(queries.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(queries.creatorId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.creatorId = value;
    this.dto.creatorName = queries.creatorName;
    this.dto.created = queries.created;
    value = Number.parseFloat(queries.modifierId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.modifierId = value;
    this.dto.modifierName = queries.modifierName;
    this.dto.modified = queries.modified;
    value = Number.parseFloat(queries.userId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.userId = value;
    this.dto.entityType = queries.entityType;
    value = Number.parseFloat(queries.entityId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.entityId = value;
    value = Number.parseFloat(queries.statusPercentage);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.statusPercentage = value;
  }
}
