import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import Vue from 'vue';
import LearningPathResourceModel from '../../generated/types/model/resource/LearningPathResourceModel';

export default class LearningPathResourceViewModel extends LearningPathResourceModel {
  // For instructions on how to use ViewModels visit:
  // https://glittrsoftware.visualstudio.com/glittr.core/_wiki/wikis/glittr.core.wiki/155/ViewModels-Models-DTOs

  get hasAccess() {
    return Vue.$service.api.users.getHasAccessToContent(this.isPublic ?? false);
  }

  get learningPathEntityType() {
    return 'learningPath';
  }

  get getStartButtonText() {
    if (this.learningProgress === undefined) {
      return Vue.$t('app.unitOrPath.start');
    } if (this.learningProgress.statusPercentage! < 100) {
      return Vue.$t('app.unitOrPath.resume');
    } return Vue.$t('app.unitOrPath.restart');
  }

  schema = {
    ...queryBuilder.properties({
      title: { required: true, maxLength: 100 },
      subTitle: { maxLength: 250 },
      shortCode: { required: true, maxLength: 20 },
      comment: { maxLength: 4000 },
      contentTypePath: { maxLength: 255 },
      levelId: { required: true },
    }),
  } as IJSONSchema;
}
