













import GetLearningPathUnitPagedListRequestViewModel from '@/src/services/viewModel/request/LearnitUnits/GetLearningPathUnitPagedListRequestViewModel';
import Vue from 'vue';

export default Vue.extend({
  name: 'KtglLearningPathUnitsLookup',
  props: {
    label: { type: String, default: undefined },
    value: { type: String, default: undefined },
    excludeIds: { type: Array, default: () => ([]) },
  },
  data: () => ({
    items: [] as any[],
    isLoading: false,
    search: undefined as string | undefined,
  }),
  computed: {
    computedItems(): any[] {
      return this.items.filter((i) => !this.excludeIds.includes(i.id));
    },
    labelComputed(): string {
      return this.label ?? this.$t('learnit.glossary.learningUnit');
    },
  },
  methods: {
    async populateData() {
      this.isLoading = true;
      const req = new GetLearningPathUnitPagedListRequestViewModel();
      req.searchCriteria = this.search;
      const response = await this.$service.api.learnitUnits.getLearningPathUnitPagedList(req);
      this.items = response.items.map((i) => ({ caption: i.title, id: i.id, detail: i.shortCode }));
      this.isLoading = false;
    },
  },
});
