import Vue from 'vue';
import core, { CoreOptions } from '@glittr/frontend-core';
import router from './router';
import services from './services';
import ktglLanguageSelection from './components/ktgl-language-selection.vue';
import ktglLearningEntryCard from './components/ktgl-learning-entry-card.vue';
import ktglLearningEntry from './components/ktgl-learning-entry.vue';
import ktglDocumentImage from './components/ktgl-document-image.vue';
import ktglLearningLock from './components/ktgl-learning-lock.vue';
import ktglLearningFavorite from './components/ktgl-learning-favorite.vue';
import ktglLearningPathUnitsLookup from './components/lookups/ktgl-learning-path-units-lookup.vue';
import ktglLearningProgress from './components/ktgl-learning-progress.vue';

Vue.config.productionTip = false;
// Core entry point
Vue.use(core, {
  router,
  Vue,
} as CoreOptions);

// APP____________________________________________________________
Vue.component('KtglLanguageSelection', ktglLanguageSelection);
Vue.component('KtglLearningEntryCard', ktglLearningEntryCard);
Vue.component('KtglLearningEntry', ktglLearningEntry);
Vue.component('KtglDocumentImage', ktglDocumentImage);
Vue.component('KtglLearningLock', ktglLearningLock);
Vue.component('KtglLearningFavorite', ktglLearningFavorite);
Vue.component('KtglLearningPathUnitsLookup', ktglLearningPathUnitsLookup);
Vue.component('KtglLearningProgress', ktglLearningProgress);

// STYLES
require('./assets/styles/main.scss');

// PLUGINS
Vue.use(services);

// COMPONENTS
// Vue.component('PComponent', PComponent);

// GUARDS
// Vue.$guards.push(yourGuard);

// LAYOUTS
const reqLayouts = require.context('./views/layouts', true);
reqLayouts.keys().forEach((componentPath) => {
  let componentName = componentPath.substr(componentPath.lastIndexOf('/') + 1, componentPath.length - 1);
  componentName = componentName.replace('.vue', '');
  const component = reqLayouts(componentPath).default;
  Vue.component(componentName, component);
});

// _______________________________________________________________

require('./store');

declare module '@core/plugins/config/config' {
  interface ConfigType {
    // add custom config props here
    'learnit-accessCodes-disabled': boolean
    'learnit-instructions-url': string
  }
}
