import Vue from 'vue';
import LearningPathUnitListResourceModel from '../../generated/types/model/resource/LearningPathUnitListResourceModel';

export default class LearningPathUnitListResourceViewModel extends LearningPathUnitListResourceModel {
  // For instructions on how to use ViewModels visit:
  // https://glittrsoftware.visualstudio.com/glittr.core/_wiki/wikis/glittr.core.wiki/155/ViewModels-Models-DTOs

  get hasAccess() {
    return Vue.$service.api.users.getHasAccessToContent(this.isPublic ?? false);
  }
}
