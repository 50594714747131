import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import UsersApi from '../generated/api/Users';

export default (service: Servicelayer) => ({
  ...UsersApi(service),

  getHasAccessToContent(isContentPublic: boolean) {
    return this.getHasAccessToPrivateContent() || isContentPublic || Vue.$auth.isInRole('Admin', 'HostAdmin');
  },

  getHasAccessToPrivateContent() {
    const isAccessCodeFunctionalityDisabled: boolean = Vue.$config.values['learnit-accessCodes-disabled'] as boolean;
    if (isAccessCodeFunctionalityDisabled) {
      return true;
    }
    const claimValue = Vue.$auth.getClaim('learnit-access');
    const hasAccess = claimValue?.toLowerCase() === 'authorized';
    return hasAccess;
  },

});
