import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import DocumentsApi from '../generated/api/Documents';

export default (service: Servicelayer) => ({
  ...DocumentsApi(service),

  // Add custom calls here
  async downloadImage(id: number) {
    const imageBlob = await this.downloadDocument(id);
    return new Blob([imageBlob], { type: 'image/*' });
  },

});
