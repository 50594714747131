
















import Vue, { PropType } from 'vue';
import LearnitFavoriteResourceViewModel from '../services/viewModel/resource/LearnitFavoriteResourceViewModel';

export default Vue.extend({
  name: 'KtglLearningFavorite',
  props: {
    value: { type: Object as PropType<LearnitFavoriteResourceViewModel | undefined>, default: undefined },
  },
  data: () => ({
    favorite: new LearnitFavoriteResourceViewModel(),
  }),
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this._.isSet(this.value)) {
          this.favorite.fromModel(this.value);
        } else {
          this.favorite = new LearnitFavoriteResourceViewModel();
        }
      },
    },
  },
  methods: {
    async toggleFavorite(favorite: LearnitFavoriteResourceViewModel) {
      if (favorite.isFavorite) {
        this.deleteFavorite(favorite);
      } else {
        this.addFavorite(favorite);
      }
    },
    async deleteFavorite(removeFavorite: LearnitFavoriteResourceViewModel) {
      try {
        await this.$service.api.favorites.deleteFavorite(removeFavorite.id!);
        removeFavorite.isFavorite = false;
        this.$emit('input', removeFavorite);
      } catch (error: any) {
        this.$dialog.notify(this.$t('app.error.errorTitle'), error.message ?? error);
      }
    },
    async addFavorite(newFavorite: LearnitFavoriteResourceViewModel) {
      try {
        await this.$service.api.favorites.addFavorite(newFavorite);
        newFavorite.isFavorite = true;
        this.$emit('input', newFavorite);
      } catch (error: any) {
        this.$dialog.notify(this.$t('app.error.errorTitle'), error.message ?? error);
      }
    },
  },
});
